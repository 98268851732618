(function($) {

	/**
	 * initializeBlock
	 *
	 * Adds custom JavaScript to the block HTML.
	 *
	 * @date    15/4/19
	 * @since   1.0.0
	 *
	 * @param   object $block The block jQuery element.
	 * @param   object attributes The block attributes (only available when editing).
	 * @return  void
	 */
	var initializeBlock = function($block) {
		//$block.find('img').doSomething();
		console.log("BLOCK | Init | ", "Section Image Counter")



		// Scroll Magic
		function scrolltime() {
			//DEV MODE
			var DEVMODE = false;
			//Scroll Magic Controller
			var sm_cont = new ScrollMagic.Controller({});
			var sm_cont2 = new ScrollMagic.Controller({});

			//universal parallax
			var sm_e_parallax = $("#acf-section-image-counter .hero");
			if (sm_e_parallax.length) {
				sm_e_parallax.each(function() {
					var sm_s_parallax = new ScrollMagic.Scene({
							triggerElement: this,
							triggerHook: "onEnter",
							duration: "200%"
						})
						.setTween(TweenMax.to(sm_e_parallax.children(".bg-wrap").children(".bg"), 1, {
							y: '-25%',
							autoAlpha: 1,
							ease: Power0.easeNone
						}))
						.addTo(sm_cont);
					if (DEVMODE) {
						sm_s_parallax.addIndicators({
							name: "parallax",
							indent: 0,
							colorStart: "#07ff00",
							colorEnd: "#ff0000",
							colorTrigger: "#ff9700"
						})
					};
				});
			}

			//Counters
			var tl_h1 = new TimelineMax({
				delay: 0,
				repeat: 0,
				repeatDelay: 0
			})
			var counter1 = {
				var: 0
			};
			var counter2 = {
				var: 0
			};
			var counter3 = {
				var: 0
			};
			var stat1 = document.getElementById("stat1");
			var stat2 = document.getElementById("stat2");
			var stat3 = document.getElementById("stat3");
			if (stat1 && stat2 && stat3) {
				stat1.innerHTML = 0;
				stat2.innerHTML = 0;
				stat3.innerHTML = 0;
				tl_h1.add(TweenMax.to(counter1, 4, {
					var: parseInt($("#stat1").data("val")),
					onUpdate: function() {
						stat1.innerHTML = Math.ceil(counter1.var).toLocaleString(undefined, {
							minimumFractionDigits: 0
						});
					},
					ease: Circ.easeOut,
					delay: 0
				}));
				tl_h1.add(TweenMax.to(counter2, 4, {
					var: parseInt($("#stat2").data("val")),
					onUpdate: function() {
						stat2.innerHTML = Math.ceil(counter2.var).toLocaleString(undefined, {
							minimumFractionDigits: 0
						});
					},
					ease: Circ.easeOut,
					delay: -4
				}));
				tl_h1.add(TweenMax.to(counter3, 4, {
					var: parseInt($("#stat3").data("val")),
					onUpdate: function() {
						stat3.innerHTML = Math.ceil(counter3.var).toLocaleString(undefined, {
							minimumFractionDigits: 0
						});
					},
					ease: Circ.easeOut,
					delay: -4
				}));
				var sm_s_h1 = new ScrollMagic.Scene({
						triggerElement: '#acf-section-image-counter',
						triggerHook: "onEnter",
						offset: 300
					})
					.setTween(tl_h1)
					.addTo(sm_cont2);
				if (DEVMODE) {
					sm_s_h1.addIndicators({
						name: "Counter",
						indent: 0,
						colorStart: "#07ff00",
						colorEnd: "#ff0000",
						colorTrigger: "#ff9700"
					})
				};
			}

			//Icons
			var c = $("#acf-section-image-counter .icon")
			var i
			if(c.length){
				for (i = 0; i < c.length; i++) {
					console.log("ELE: ", i, this);
					var tl_u = new TimelineMax({})
					tl_u.add(
						TweenMax.staggerFromTo(
							'#acf-section-image-counter .icon',
							1.5, {
								opacity: '0',
								top: '75px',
								ease: Sine.easeInOut
							}, {
								opacity: '1',
								top: 0,
								ease: Sine.easeInOut
							},
							0
						)
					)
					var sm_s_h2 = new ScrollMagic.Scene({
							triggerElement: '#acf-section-image-counter',
							triggerHook: "onEnter",
							offset: 300
						})
						.setTween(tl_u)
						.addTo(sm_cont);
					if (DEVMODE) {
						sm_s_h2.addIndicators({
							name: "Counter",
							indent: 0,
							colorStart: "#07ff00",
							colorEnd: "#ff0000",
							colorTrigger: "#ff9700"
						})
					};
				}
			}


		};

		scrolltime();

	}

	// Initialize each block on page load (front end).
	$(document).ready(function() {
		$('#acf-section-image-counter').each(function() {
			initializeBlock($(this));
		});
	});
	// Initialize dynamic block preview (editor).
	if (window.acf) {
		//console.log("Window ACF")
		window.acf.addAction('render_block_preview', initializeBlock);
	}

})(jQuery);